* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, "MS Trebuchet", sans-serif;
    width: 70%;
    min-width: 600px;
    position: relative;
    left: 15%;
}

div[id="root"] {
    left: 0;
    top: 0;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(250, 250, 210, 1);
    border-radius: 10px;
}

.page_header,
.menu,
.pagename h3,
.lakefilter,
#laketype,
.footer,
.lake a,
.lakeform,
.lakeform header,
.lakereserve,
.login,
.pageBlock {
    border-radius: 10px;
}

.menu a,
.lakefilter input,
.lake p,
.lake h3,
legend,
.lakeform h2,
.login input,
.page_header h1 {
    border-radius: 5px;
}

.menu,
.pagename,
h2,
h3,
.lakefilter legend,
.lake a,
#laketype,
.lakeform header,
.services .hyphen,
.services .price,
.placesname,
.placestatus,
.placetime,
.places .hyphen,
.login legend,
.footer {
    text-align: center;
}

.delim {
    margin: 0 auto 20px auto;
}

.page_header {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px 0;
}

.mainBanner {
    width: 60%;
    align-self: center;
    margin: -20px 0 -30px 0;
}

.page_header h1 {
    font-style: italic;
    font-size: 20px;
    background-color: #FAFAD2;
    color: black;
    padding: 0 5px 0 5px;
    flex: 0 1 auto;
    align-self: flex-end;
}

.pageBlock {
    display: block;
    background-color: #EAEAEA;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.pageBlock.aboutArticle {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0 0 5px 0;
}

.pageBlock.adminBlock {
    padding: 20px;
    margin: 0 0 5px 0;
}

.adminBlock.adminStats {
    display: flex;
    flex-direction: row;
}

.adminField {
    flex: 1 0 auto;
    margin: 0 5px 0 0;
}

.invData {
    display: flex;
    flex-direction: column;
}

.invDataItem {
    flex: 1 0 auto;
}

.button {
    padding: 2px 7px;
    border: none;
    text-decoration: none;
    text-align: center;
    color: black;
    font-style: italic;
    font-weight: bold;
    display: block;
    border-radius: 15px;
    box-shadow: 2px 5px 10px rgba(0,0,0,0.25), 2px 3px 3px rgba(0,0,0,0.22);
    transition: .15s;
    cursor: pointer;
}

.button.setPaid {
    padding: 5px;
}

.sb_disable {
    background-color: rgba(240, 128, 128, 1);
    color: #fff;
}

.sb_enable {
    background-color: rgba(154, 205, 50, 0.8);
    color: #fff;
}

.select {
    text-align: center;
    font-style: italic;
    font-weight: bold;
    border: none;
    flex: 0 1 100px;
}

.select.quanType {
    margin: 0;
}

.menu {
    clear: both;
    display: block;
    margin: 0;
    padding: 5px 10px;
}

.menu > ul {
    list-style: none;
    display: flex;
    justify-content: center;
}

.menu > ul > li {
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
}

.menu .menuItem {
    font-weight: bold;
    text-decoration: none;
    color: #F5F5F5;
    padding: 3px 10px;
    transition: .1s;
}

.burgerMenu {
    display: none;
}

.fieldbase {
    margin: 0 auto;
}

.fieldbase .hyphen {
    background-color: initial;
    box-shadow: none;
    padding: 1px 3px;
    flex: 0 0 auto;
}

.fieldname {
    color: #fff;
    font-style: initial;
}

.fielddata {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid #6B8E23;
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 3px 9px;
}

.select.quanType,
.services .servicename,
.services .price,
.services .priceEdit .inputText {
    box-shadow: 2px 5px 10px rgba(0,0,0,0.25), 2px 3px 3px rgba(0,0,0,0.22);
}

.services.order .servicename,
.services.order .price {
    box-shadow: none;
    border: 1px solid #6b8e23;
}

.services.info .price {
    background-color: rgba(255, 233, 0, 0.9);
}

.period {
    background-color: transparent;
    padding: 0 0;
}

.services .servicename {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.services .serviceslegend,
.places .placeslegend {
    padding: 0 0 5px 0;
}

.services .hyphen {
    padding: 0 10px 0 6px;
    margin: 0 auto;
    color: #fff;
    font-weight: bold;
}

.services.config .priceEdit .inputText {
    margin: 0 0;
    width: 56px;
    font-size: 100%;
    text-align: center;
}

.services .price.servType {
    padding: 0 3px;
}

.header3 {
    padding: 5px 10px;
    display: inline-block;
}

.pagename .header3 {
    background-color: #EAEAEA;
    box-shadow: 2px 5px 10px rgba(0,0,0,0.25), 2px 3px 3px rgba(0,0,0,0.22);
}

.pagename {
    opacity: 80%;
    margin: 0 0 20px 0;
}

.lakefilter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    background-color: #EAEAEA;
    padding: 0 0 5px 0;
    margin: 0 0 20px 0;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.lakefilter .filterheader {
    margin: 5px 0 10px 0;
}

.lakefilter .flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.lakefilter .subInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3px 5px;
    margin: 0;
}

.lakefilter .subInfo .fieldname {
    flex: 0 0 auto;
    padding: 0 5px;
}

.lakefilter .subInfo .select {
    flex: 1 0 auto;
    margin: 0 0;
}

.lake {
    border: none;
    margin: 0 0 3% 0;
}

.lake ol {
    list-style: none;
    display: none;
}

.lake li {
    display: inline-block;
}

.lake h3 {
    display: inline-block;
    padding: 3px;
    margin: 10px 0 20px 0;
    background-color: #EAEAEA;
}

.lake a {
    display: block;
    font-style: italic;
    text-decoration: none;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-color: #EAEAEA;
    color: #FF8C00;
    padding: 10px;
    min-height: 130px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    filter: opacity(80%);
    transition: .1s;
}

#laketype {
    color: black;
    font-size: 80%;
    font-style: italic;
    width: 87px;
    height: 18px;
    display: block;
    box-shadow: 2px 5px 10px rgba(0,0,0,0.25), 2px 3px 3px rgba(0,0,0,0.22);
}

legend.private {
    background-color: #FFD700;
}

legend.free {
    background-color: #9ACD32;
}

#address,
#coordinates {
    text-align: left;
    color: #fff;
    font-style: italic;
    padding: 2px;
    display: block;
}

.lakeform {
    display: block;
    font-style: italic;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-color: #EAEAEA;
    padding: 20px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.lakeform #address,
.lakeform #coordinates {
    text-align: center;
}

.lakeform h2 {
    display: inline-block;
    padding: 3px 9px;
    margin: 0 0 30px 0;
    background-color: #EAEAEA;
}

.lakeform .fieldbase {
    max-width: 92%;
    margin: 10px auto 0 auto;
}

.lakeform .fieldname {
    margin: 20px 0 0 10px;
}

.lakeform .fielddata {
    background-color: rgba(255, 233, 0, 0.9);
    box-shadow: 2px 5px 10px rgba(0,0,0,0.25), 2px 3px 3px rgba(0,0,0,0.22);
    border: none;
}

.services .fieldname {
    margin: 0;
}

.lakeform .services {
    margin: 30px auto 0 auto;
}

.rulesList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nonClientInfo {
    margin: 20px;
    font-style: italic;
    font-weight: bold;
    text-align: center;
}

.lakereserve {
    display: block;
    background-color: #EAEAEA;
    padding: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.day {
    text-decoration: none;
    font-size: 14px;
    text-align: center;
    color: black;
    font-weight: bold;
    margin: 3px 3px;
    padding: 7px 5px;
    border: none;
    flex: 1 1 12%;
    box-shadow: 2px 5px 10px rgba(0,0,0,0.25), 2px 3px 3px rgba(0,0,0,0.22);
}

.days {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.days .button:nth-child(1) {
    background-color: rgba(255, 0, 0, 0.8);
}

.days .button:nth-child(2) {
    background-color: rgba(255, 165, 0, 0.8);
}

.days .button:nth-child(3) {
    background-color: rgba(255, 255, 0, 0.8);
}

.days .button:nth-child(4) {
    background-color: rgba(173, 255, 47, 0.8);
}

.days .button:nth-child(5) {
    background-color: rgba(50, 255, 100, 0.8);
}

.days .button:nth-child(6) {
    background-color: rgba(0, 255, 170, 0.8);
}

.days .button:nth-child(7) {
    background-color: rgba(0, 200, 200, 0.8);
}

.lakereserve:first-child {
    display: block;
}

.lakescheme {
    border-radius: 50px;
    background-color: rgba(173, 216, 230, 1);
    padding: 20px;
    margin: 20px auto 0 auto;
    width: 580px;
}

.lakescheme header {
    font-style: italic;
    display: block;
    text-align: center;
}

.places {
    width: 90%;
}

.places,
.order {
    margin: 0 auto;
}

.places .hyphen {
    color: #fff;
    padding: 0 6px;
}

.places .placesname {
    border: none;
}

.places .placesname {
    display: inline-flex;
    align-items: center;
    /*height: 25px;*/
}

.placesname,
.placestatus,
.placetime {
    font-style: italic;
/*    background-color: rgba(255, 233, 0, 0.9);*/
    box-shadow: 2px 5px 10px rgba(0,0,0,0.25), 2px 3px 3px rgba(0,0,0,0.22);
}

.placetime {
    font-size: 11px;
    font-weight: bold;
}

.placefree .placesname,
.placefree .placestatus {
    background-color: rgba(211, 211, 211, 1);
}

.placefree .buttonAddFisher {
    background-color: rgba(154, 205, 50, 0.8);
    color: #fff;
}

.placeinactive .placesname,
.placeinactive .placestatus {
    background-color: rgba(240, 128, 128, 1);
}

.placereserved .placesname,
.placereserved .placestatus,
.placereserved .placetime,
.placereserved .advanceField .contextButton.Dialog {
    background-color: rgba(173, 255, 47, 1);
}

.placereserved .advanceField {
    width: 100%;
}

.placebusy .placesname,
.placebusy .placestatus,
.placebusy .placetime,
.placebusy .button {
    background-color: rgba(255, 233, 0, 0.9);
}

.placebusy .placestatus /*.advanceField*/ {
    width: 100%;
}

.placeButtonClean .button {
    background-color: rgba(255, 0, 0, 0.8);
    color: #fff;
}

.reservForm {
    margin: 30px auto 0 auto;
    background-color: rgba(211, 211, 211, 1);
    padding: 10px;
    width: 500px;
    box-shadow: 2px 5px 10px rgba(0,0,0,0.25), 2px 3px 3px rgba(0,0,0,0.22);
}

.reservForm h3 {
    margin: 0 0 10px 0;
}

.reservForm .fieldbase {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto 10px auto;
}

.reservForm .fisherInfoBase {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reservForm .fisherData {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    margin: 0 0 10px 0;
}

.reservForm .fisherIdBase {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 50%;
}

.reservForm .fisherData .inputText {
    margin: 0 0 10px 0;
}

.reservForm .fisherData .inputText:last-child {
    margin: 0;
}

@media (min-width: 541px) {
    .reservForm .fielddata {
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid #6B8E23;
    }
}

.timePeriod {
    margin: 0;
    padding: 0;
}

.timePeriod .hyphen {
    color: #000000;
}

.dateTime {
    padding: 0 0 0 10px;
    text-align: center;
    background-color: initial;
    border: none;
    font-style: italic;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.order .servicename,
.order .price {
    background-color: rgba(255, 255, 255, 1);
}

.advanceField {
    padding: 2px 3px 2px 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.advanceField .contextButton {
    box-shadow: none;
}

.advanceField input {
    margin: 0;
    width: 100%;
    font: inherit;
    text-align: center;
    flex: 0 1 auto;
    border: none;
    box-shadow: none;
    background-color: transparent;
}

.contextButton {
    font-weight: bold;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(107, 142, 35, 0.5);
/*    padding: 0 3px;*/
/*    margin: 2px 0px 2px 3px;*/
    margin: 0px 0px 0px 3px;
    border-radius: 50% 50%;
    box-shadow: none;
    flex: 0 0 auto;
}

.contextButton.Minus {
    padding: 2px 8px 2px 8px;
    border-radius: 50% 50%;
    background-color: rgba(240, 128, 128, 0.6);
    color: #fff;
}

.contextButton.Plus {
    padding: 2px 6px 2px 6px;
    border-radius: 50% 50%;
    background-color: rgba(154, 205, 50, 0.6);
    color: #fff;
}

.contextButton.Dialog {
    padding: 2px 5px 2px 5px;
}

.order .default .servicename,
.order .default .price {
    background-color: rgba(173, 216, 230, 1);
}

.order .newItem {
    justify-content: flex-end;
}

.order .summary .summaryLabel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px auto;
    padding: 0 3px 0 0;
}

.reservForm .buttonsArea .button {
    background-color: rgba(255, 165, 0, 0.8);
    padding: 6px 9px;
    color: #fff;
}

.buttonsArea .button[type="submit"] {
    background-color: rgba(154, 205, 50, 0.8);
}

.buttonsArea .button.orderReject {
    background-color: rgba(250, 50, 0, 0.8);
}

.lakeform h2,
.fielddata,
.price,
.places td,
.reservForm,
.dateTime,
.advanceField input {
    border-radius: 15px;
}

.login {
    margin: 0 auto;
    padding: 5px;
    border: none;
    background-color: #EAEAEA;
    width: 250px;
    height: 30%;
    box-shadow: 2px 5px 10px rgba(0,0,0,0.25), 2px 3px 3px rgba(0,0,0,0.22);
}

.login legend {
    white-space: nowrap;
    font-size: 90%;
    margin: 4% 0 0 14%;
    width: 65%;
    height: 15%;
}

.login ol {
    list-style: none;
    margin: 3% 1% 1% 8%;
}

.login li {
    margin: 5% 0 5% 0;
}

.login input {
    background-color: #fff;
    border: 1px solid #6B8E23;
    display: block;
    width: 90%;
    height: 18px;
}

.login .button {
    color: black;
    background-color: #FFD700;
    border: none;
    margin: 6% 0 6% 26%;
    width: 40%;
    height: 20px;
}

.formRegister {
    padding: 20px;
}

.halfBlockContainer {
    display: flex;
    justify-content: space-between;
}

.halfBlock {
    flex: 1 1 50%;
}

.halfBlock .inputText,
.halfBlock .addressInfo {
    width: 100%;
}

.commerseData {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0 5px;
    width: 50%;
}

.commerseData .fielddata {
    display: flex;
    margin: 0 0 20px 0;
}

.commerseData .fielddata .label {
    margin: 0 10px 0 0;
}

.commerseData .header2 {
    margin: 0 0 20px 0;
}

.commerseData .requisites {
    flex-direction: column;
    width: 100%;
}

.paymentTarget .fielddata {
    flex: 0 1 30%;
}

.commerseData .requisites .fielddata {
    margin: 0;
    justify-content: space-between;
}

.commerseData .requisites .requisiteField {
    margin: 5px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.commerseData .requisites .requisiteField.iban {
    flex-direction: column;
}

.commerseData input[name="promocode"] {
    margin: 0;
}

.commerseData .promo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mainInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.subInfo {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mainInfo .fielddata,
.subInfo .fielddata {
    margin: 0 0 20px 0;
    box-shadow: none;
    flex: 0 1 auto;
    font: inherit;
    text-align: center;
}

/* .subInfo .button {
    margin: 0;
} */

.formRegister .subInfo > .inputText {
    width: 40%;
}

.formRegister .advanceField .contextButton {
    margin: 0px 0px 0px 3px;
    padding: 2px 5px;
}

.textArea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    font: inherit;
}

.lakePhoto {
    margin: 0 0 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.photo {
    margin: 0 5px 10px 0;
    padding: 0;
    flex: 0 0 auto;
    height: 110px;
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    border-radius: 13px;
}

.photo.image {
    margin: 0;
}

.photo .contextButton.Minus {
    margin: 3px 3px 0 0;
    padding: 3px 9px;
}

.newPhoto {
    padding: 0;
    background-color: rgba(105, 105, 105, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.lakePhoto .contextButton.Plus {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: rgba(105, 105, 105, 0.3);
    border-radius: 15px;
}

.formRegister .fieldname {
    margin: 0 0 10px 0;
    color: black;
}

.formData .fieldbase {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 20px 0;
    padding: 0;
}

.formData .advanceField {
    flex: 0 0 auto;
}

.formData .advanceField .contextButton.Minus {
    padding: 3px 9px;
}

.formData .dynamicList .contextButton.Plus {
    padding: 3px 8px;
    margin: 2px 0px;
}

.formData .services .newItem .contextButton.Plus {
    padding: 1px 6px;
}

.formData .services .servicename,
.formData .services .price {
    background-color: rgba(255, 255, 255, 1);
}

.formData .services .newItem {
    justify-content: flex-end;
}

.formData .services .default {
    padding: 3px 0 3px 0;
    width: 24px;
}

.services.config .newItem {
    background-color: initial;
    box-shadow: initial;
    border: none;
    padding: 0 3px;
    margin: 5px 0 0 0;
    display: flex;
    justify-content: flex-start;
}

.advanceField.contextButton.Plus {
/*    padding: 2px 7px;*/
    border: none;
    background-color: rgba(105, 105, 105, 0.3);
}

.buttonSubmit,
.buttonReset {
    margin: 0 auto;
    padding: 7px 9px;
    background-color: rgba(255, 233, 0, 0.9);
}

.wrapperPopup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(105, 105, 105, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease 0s;
}

.wrapperPopup {
    opacity: 0;
    visibility: hidden;
}

.wrapperPopup.open {
    opacity: 1;
    visibility: visible;
}

.wrapDisable {
    background-color: rgba(105, 105, 105, 0.3);
    transition: .15s;
}

.wrapEnable {
    transition: .15s;
}

.staticList .listItem {
    margin: 0 5px 5px 0;
}

.inputList {
    padding: 20px 40px 15px 40px;
    width: auto;
    z-index: 200;
    overflow: hidden;
    border-radius: 30px;
}

.inputList .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.inputList .listItem {
    flex: 0 0 auto;
    margin: 0 5px 5px 0;
}

.inputUserText {
    padding: 20px 40px 15px 40px;
    width: auto;
    z-index: 300;
    overflow: hidden;
    border-radius: 30px;
}

.inputUserText .text {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.inputUserText .textArea {
    margin: 0 0 5px 0;
}

.inputPhoto {
    padding: 10px 5px 15px 5px;
    width: auto;
    z-index: 300;
    overflow: hidden;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inputPhoto .photoList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 320px;
    height: 320px;
    margin: 0 0 5px 0;
    overflow-y: auto;
}

.inputPhoto .Plus {
    font-size: 110%;
    margin: 0 0 5px 0;
    padding: 2px 7px;
}

.inputPhoto .buttonsArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inputPhoto .photo {
    margin: 0;
    flex: 1 0 30%;
}

.inputPhoto .inputFile {
    margin: 0 0 5px 0;
}

.fileSizeWarn {
    font-size: 12px;
    color: rgb(248, 93, 4);
}

.formAddress {
    padding: 20px 40px;
    width: 400px;
    z-index: 100;
    /*overflow: hidden;*/
    border-radius: 20px;
}

.formAddress.mainInfo {
    justify-content: space-around;
    align-items: center;
}

.formAddress .header3 {
    margin: 0 0 20px 0;
}

.formAddress .fielddata {
    width: 100%;
    text-align: left;
}

.formLocation {
    padding: 20px 20px;
    width: 270px;
    z-index: 100;
    overflow: hidden;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.formLocation .header3 {
    margin: 0 0 10px 0;
}

.lakeconfig .headerButtons {
    margin: 5px auto auto auto;
    display: flex;
    justify-content: space-between;
}

.lakeconfig .headerButtons .button {
    font-style: normal;
}

.lakeconfig .headerButtonsLeft {
    flex: 0 1 auto;
    display: flex;
}

.lakeconfig .headerButtonsRight {
    flex: 0 1 20%;
    display: flex;
    justify-content: space-between;
}

.lakeconfig .footerButtons {
    margin: 5px auto auto auto;
    display: flex;
    justify-content: flex-end;
}

.lakeconfig .lakescheme .places {
    margin: 20px auto auto auto;
}

.lakeconfig .lakescheme .inputText {
    text-align: center;
    font-size: 100%;
    font-style: italic;
}

.lakeconfig .lakescheme header > .inputText {
    min-width: 300px;
    margin: 0 0 10px 0;
}

.lakeconfig .name,
.lakeconfig .new {
    margin: 0 0;
    padding: 0 0;
    display: flex;
    justify-content: space-between;
}

.lakeconfig .name .contextButton.Minus {
    margin: 0px 0px 0px 5px;
}

.lakeconfig .new .advanceField {
    margin: 10px 0 0 0;
    padding: 3px 3px 3px 0;
}
/*
.lakeconfig .new .placeNum {
    margin: 0 0;
    padding: 0 2px 0 1px;
    max-width: 35px;
    text-align: center;
    font-size: 100%;
    font-style: italic;
}
*/
.footer {
    display: flex;
    flex-direction: column;
    margin: 20px 0 0 0;
    padding: 20px;
    background-color: rgba(105, 105, 105, 0.9);
}

.footer .horizontalBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer .horizontalBlock .footerContacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.footer .horizontalBlock .footerPages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer .footerPartners {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 10px 0;
}

.footer .footerContact a,
.footer .footerPage {
    color: #ffffff;
}

.footerContacts .footerContact {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 0 10px 0;
    color: white;
}

.footerContactGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 5px 0;
}

.footerContactGroup .footerContact {
    display: flex;
    margin: 0 10px 0 0;
}

.coffeeIcon {
    display: flex;
    margin: 0 0 0 10px;
}

.daychecked {
    flex: 0 0 auto;
    background-color: white;
    box-shadow: none;
}

.passEq {
    background-color: greenyellow;
}

.passNotEq {
    background-color: rgb(245, 84, 84);
}

.publicOffer {
    margin: 10px 0 0 0;
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
}

.activateMessage {
    padding: 20px;
}

.benefits {
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.benefit {
    flex: 0 0 auto;
    margin: 0 0 5px 0;
}

.boiliesList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.boilieItem {
    width: 290px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0 5px 0 0;
    padding: 3px;
}

.boilieImage {
    margin: 0 0 5px 0;
    box-shadow: none;
}

.boilieItem .benefits {
    flex-direction: row;
    justify-content: space-between;
}

.boilieItem .benefit {
    font-size: small;
    margin: 0;
}


/*------------------------ :Hovers section ------------------------*/

.menu .menuItem:hover {
    background-color: #FAFAD2;
    color: #FF8C00;
    margin: 0 1% 0 0.1%;
}

.button:hover,
.button[type="submit"]:hover,
/*.days .fielddata:hover,*/
.days .button:nth-child(n):hover,
.reservForm .buttonsArea .button:hover,
.placereserved .advanceField .contextButton:hover,
.advanceField.contextButton.Plus:hover {
    background-color: rgba(250, 250, 210, 1);
    color: rgba(255, 140, 0, 1);
}

input:hover,
.dateTime:hover,
.fielddata .inputText:hover,
.select:hover {
    background-color: rgba(250, 250, 210, 1);
}

.lake a:hover {
    filter: opacity(100%);
}

/*------------------------ :Actives section ------------------------*/

.menu .menuItem:active {
    padding: 3px 3px;
    background-color: transparent;
}

.lake a:active {
    transition-timing-function: ease-out;
    transform: scale(1.05, 1.1);
}

.button:active,
.button[type="submit"]:active,
.days .button:nth-child(n):active,
.reservForm .buttonsArea .button:active,
.placereserved .advanceField .contextButton:active,
.advanceField.contextButton.Plus:active {
    background-color: initial;
    box-shadow: none;
    color: initial;
}

/*------------------------ @media section ------------------------*/

@media (max-width: 768px) {

    body {
        min-width: initial;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    body.lock {
        overflow: hidden;
    }

    .burgerBody {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(211, 211, 211, 1);
    }

    .burger {
        position: relative;
        height: 20px;
        flex: 0 0 30px;
        transition: all 0.3s ease 0s;
    }

    .burger.active {
        transform: rotate(45deg);
    }

    .burger span {
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 3px;
        left: 0;
        top: 9px;
    }

    .burger:before,
    .burger:after {
        content: '';
        left: 0;
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 3px;
    }

    .burger:before {
        top: 0;
    }

    .burger:after {
        bottom: 0;
    }

    .page_header {
        position: fixed;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        width: 100%;
        border-radius: initial;
        z-index: 30;
        top: 0;
    }

    .page_header h1 {
        align-self: center;
    }

    .pageBlock {
        width: 100%;
    }

    .menu {
        display: none;
        margin: 0;
    }

    .menu > ul {
        width: 100%;
    }
    
    .burgerMenu {
        display: block;
        position: fixed;
        top: -100%;
        left: 0;
        overflow: auto;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 57px 0 0 0;
        background-color: rgba(211, 211, 211, 1);
        z-index: 1;
        transition: all 0.3s ease 0s;
    }

    .burgerMenu.active {
        top: 0;
    }
    
    .burgerMenu .menu {
        display: flex;
        justify-content: center;
        z-index: 2;
    }
    
    .burgerMenu .menu ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 0 0;
    }

    .menu li {
        margin: 0 0 20px 0;
        display: flex;
        flex: 1 0 auto;
        width: 100%;
    }

    .menuItem {
        flex: 1 0 auto;
        width: 100%;
        font-size: 23px;
    }

    .pagename {
        margin: 60px 0 20px 0;
    }
    
    .lakeform {
        margin: 60px 0 0 0;
        padding: 10px;
    }

    .days {
        width: 100%;
    }

    .lakescheme {
        width: 100%;
    }

    .inputUserText,
    .inputList,
    .formLocation {
        display: flex;
        align-items: center;
        height: auto;
    }

    .popup {
        /* height: 80%; */
        width: auto;
        margin: 60px 0 0 0;
        overflow-y: auto;
    }

    .mainBanner {
        width: 30%;
        padding: inherit;
        margin: -30px 0 -30px 0;
    }
    
    .boilieItem {
        width: 49%;
        margin: 0 5px 0 0;
    }
}

@media (max-width: 620px) {

    .formRegister .mainInfo > .inputText,
    .formRegister .mainInfo > .addressInfo,
    .formRegister .subInfo > .inputText {
        width: 100%;
    }

    .inputUserText,
    .inputList,
    .formLocation {
        display: flex;
        align-items: center;
        height: auto;
    }

    .commerseData .requisites .requisiteField {
        flex-direction: column;
        padding: 3px 0;
    }

    .lakeInfo .halfBlockContainer {
        flex-direction: column;
    }

    .commerseData {
        width: 100%;
    }

    .fieldbase {
        margin: 0;
    }

    .inputPhoto .photoList {
        width: 100%;
    }
}

@media (max-width: 540px) {

    .reservForm {
        width: 100%;
        box-shadow: none;
    }

    .timePeriod {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
    }

    .timePeriod .dateTime,
    .timePeriod .hyphen,
    .reservForm .select,
    .order .servicename,
    .order .price {
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid #6B8E23;
    }

    .timePeriod .dateTime:hover,
    .reservForm .select:hover {
        background-color: rgba(250, 250, 210, 1);
    }

    .formRegister .subInfo {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .mainInfo .inputText {
        width: 100%;
    }

    .formAddress {
        width: 90%;
    }

    .fisherInfoBase {
        flex-direction: column;
    }

    .fisherInfoBase .fisherData,
    .fisherInfoBase .fisherData .inputText  {
        width: 100%;
    }

    .fisherInfoBase .fisherIdBase {
        visibility: hidden;
    }

    .halfBlockContainer {
        flex-direction: column;
    }
}

@media (max-height: 590px) {

    .reservForm.popup {
        height: 80%;
        width: auto;
        overflow-y: auto;
        padding: 10px 20px 10px 20px;
        margin: 60px 0 0 0;
    }
}

@media (max-height: 514px) {

    .wrapperPopup {
        height: 100%;
    }

    .popup {
        height: 80%;
        width: auto;
        overflow-y: auto;
        padding: 30px 20px 10px 20px;
        margin: 60px 0 0 0;
    }

    .formAddress {
        min-width: auto;
        min-height: auto;
    }

    .formAddress .inputText {
        padding: 3px;
    }

    .formAddress .inputText,
    .formAddress .header3 {
        margin: 0 0 3px 0;
    }

    .services.config {
        font-size: 90%;
        display: block;
        overflow-x: auto;
        padding: 10px;
    }

    .services.config tbody {
        white-space: nowrap;
    }

    .inputPhoto .photoList {
        width: 270px;
        height: 270px;
    }

    .inputPhoto {
        padding: 10px 5px 15px;
    }

    .inputUserText,
    .inputList,
    .formLocation {
        height: auto;
    }
    
    .boiliesList {
        flex-direction: row;
    }
}

@media (max-width: 500px) {
    
    .boiliesList {
        flex-direction: column;
    }
    
    .boilieItem {
        height: auto;
        width: 100%;
        margin: 0 0 5px 0;
    }
    
    .boilieItem .benefits {
        margin: 3px 0 0 0;
    }
}

@media (max-width: 425px) {
    
    .fisherInfoBase {
        flex-direction: column;
    }

    .lakefilter .flexRow {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}

@media (max-height: 330px) {

    .inputPhoto .photoList {
        width: 270px;
        height: 130px;
    }

    .page_header {
        margin: 0;
        padding: 10px;
    }

    .popup {
        height: 85%;
        margin: 40px 0 0;
        overflow-y: auto;
        padding: 60px 20px 10px;
        width: auto;
    }

    .formLocation.popup {
        padding: 0 10px 10px 10px;
    }

    .reservForm.popup {
        padding: 10px;
    }

    .inputPhoto.popup {
        padding: 10px 5px 15px;
    }

    .inputList {
        height: auto;
    }
}

@media (max-width: 375px) {

    .lakeform h2 {
        margin: 10px 0;
    }

    .lakefilter .subInfo {
        flex-direction: column;
        width: 100%;
    }

    .places .placebusy,
    .places .placefree,
    .places .placeinactive,
    .places .placereserved {
        font-size: 80%;
    }

    .places .placesname {
        height: 25px;
    }

    .places .placetime {
        font-size: 70%;
        padding: 3px 3px;
    }

    .wrapperPopup {
        height: 100%;
    }

    .reservForm .fisherData {
        justify-content: space-around;
        align-items: center;
    }

    .services.info {
        display: block;
        overflow-x: auto;
    }

    .services.info,
    .services.config {
        font-size: 90%;
        display: block;
        overflow-x: auto;
    }

    .services.info tbody,
    .services.config tbody {
        white-space: nowrap;
    }

    .places {
        display: block;
        overflow-x: auto;
    }

    .places tbody {
        white-space: nowrap;
    }

    .lakeFishes .advanceField,
    .lakeCatchTypes .advanceField,
    .lakeRules .advanceField {
        margin: 0 3px 5px 0;
    }

    .lakeFishes .Plus,
    .lakeCatchTypes .Plus,
    .lakeRules .Plus {
        width: 25px;
        height: 25px;
    }

    .lakeconfig .lakescheme {
        font-size: 80%;
    }

    .lakeconfig .lakescheme input[name="LakeName"] {
        min-width: auto;
        width: 80%;
    }

    .lakeconfig .headerButtonsRight .buttonDown {
        margin: auto 3px;
    }

    .dynamicList .Plus,
    .places .Plus,
    .footerButtons .buttonAdd {
        font-style: normal;
    }

    .footer .horizontalBlock {
        flex-direction: column;
    }

    .footer .horizontalBlock .footerPages {
        margin: 10px 0 0 0;
    }

    .footer .horizontalBlock .footerContacts {
        align-items: initial;
    }

    .footerContactGroup {
        margin: 0 0 10px;
    }

    .inputPhoto {
        overflow-y: auto;
    }
}

@media (max-width: 250px) {
    
    .login {
        width: 100%;
    }
}

